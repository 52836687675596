<template>
    <v-container fluid style="padding-bottom:100px">
        <v-row>
            <v-col cols="12">
                <!-- <div class="d-flex mt-3">
                    <h5 style="font-weight: 700;">Material Consumption</h5>
                    <v-spacer></v-spacer> -->
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                <!-- </div> -->
                
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
        <v-row class="mt-2">
            <v-col cols="12">
                <v-card outlined class="p-2" style="border-radius: 4px !important;">
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Machine Type </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    clearable
                                    v-model="machine_type"
                                    :items="machine_types"
                                    item-value="mach_type"
                                    :item-text="item => item.mach_type +' - '+ item.descr.trim()"
                                    class="ma-0 mt-2 pa-0"
                                    hide-details=true
                                    @change="(event) => [getMachID(event)]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Machine ID </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    clearable
                                    v-model="machine_id"
                                    :items="machine_ids"
                                    item-value="mach_id"
                                    :item-text="item => item.mach_id +' - '+ item.descr.trim()"
                                    class="ma-0 mt-2 pa-0"
                                    hide-details=true
                                    :loading="loading_mach_id"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Start Period </span>
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        outlined
                                        dense
                                        clearable
                                        v-model="date_from"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 mt-2 pa-0"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        type="month"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">End Period </span>
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        outlined
                                        dense
                                        clearable
                                        v-model="date_to"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 mt-2 pa-0"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        type="month"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn color="info" elevation="2" class="mt-5" @click="search()"><v-icon small class="mr-3">mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>                
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col cols="12">
                <v-card outlined>
                    <v-card-text>
                        <v-sheet
                            class="mx-auto mb-1"
                        >
                            <v-slide-group
                            show-arrows
                            v-model="slide"
                            >
                                <v-slide-item v-for="(n, index) in slides"
                                :key="index"
                                @click="setSlide(index)">
                                    <v-btn
                                    class="mx-2"
                                    :color="slide == index ? 'primary white--text' : ''"
                                    outlined
                                    @click="setSlide(index)"
                                    >
                                        {{n.text}}
                                    </v-btn>
                                </v-slide-item>
                            </v-slide-group>
                        </v-sheet>
                        <v-expand-transition>
                            <v-container fluid v-show="show">
                                <v-row>
                                    <v-col cols="12" class="mb-0 pb-0">
                                        <p class="font-weight-black mt-1 mb-1">Summary</p>
                                    </v-col>
                                    <v-sheet
                                        class="mx-auto mb-1 mt-0"
                                    >
                                        <v-slide-group
                                        show-arrows
                                        >
                                            <v-slide-item class="mr-2" v-for="(summary, index) in summaries" :key="index">
                                                <v-card outlined min-width="420">
                                                    <v-card-text>
                                                        <h6 class="font-weight-black pb-0">{{summary.article}}</h6>
                                                        <v-container fluid class="p-2">
                                                            <v-row>
                                                                <v-col class="col-12" sm="12" md="12">
                                                                    <span class="pt-0 font-12 mb-1">Highest used by Month </span>
                                                                    <div :id="'container'+index" style="height: 200px; width: 100%;"></div>
                                                                    <!-- <div id="container0" style="height: 200px; width: 100%;"></div> -->
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>  
                                                    </v-card-text>                                                    
                                                </v-card>
                                            </v-slide-item>
                                        </v-slide-group>
                                    </v-sheet>
                                </v-row>
                            </v-container>
                        </v-expand-transition>
                    </v-card-text>
                </v-card>
                <v-card-actions class="mt-0 pt-0 mb-0 pb-0" style="position: relative;margin-top: -14px !important;">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="show = !show"
                        class="mb-0 pb-0"
                        small
                    >
                        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col class="col-12" sm="12" md="12">
                <v-card outlined height="450">
                    <v-card-text>
                        <p class="font-weight-black mt-0">Production Output</p>
                        <v-img
                        class="text-center mt-12 mx-auto"
                        lazy-src="@/assets/empty_data.png"
                        max-width="300"
                        src="@/assets/empty_data.png"
                        v-if="showData"
                        ></v-img>
                        <div id="containerProduction" style="height: 370px; width: 100%;"></div> 
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-12" sm="6" md="6">
                <v-card outlined height="450">
                    <v-card-text>
                        <p class="font-weight-black mt-0">Ratio Material Used (%)</p>
                        <v-img
                        class="text-center mt-12 mx-auto"
                        lazy-src="@/assets/empty_data.png"
                        max-width="300"
                        src="@/assets/empty_data.png"
                        v-if="showData"
                        ></v-img>
                        <div id="containerRatioMaterialUsed" style="height: 370px; width: 100%;"></div> 
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-12" sm="6" md="6">
                <v-card outlined height="450">
                    <v-card-text>
                        <p class="font-weight-black mt-0">Material Used</p>
                        <v-img
                        class="text-center mt-12 mx-auto"
                        lazy-src="@/assets/empty_data.png"
                        max-width="300"
                        src="@/assets/empty_data.png"
                        v-if="showData"
                        ></v-img>
                        <div id="containerMaterialUsed" style="height: 370px; width: 100%;"></div> 
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Sunrise Mill',
                disabled: false,
                href: '/admin/sm',
                },
                {
                text: 'Production',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Material Consumption',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            loading: false,
            machine_type: '',
            machine_types: [],
            machine_id: '',
            machine_ids: [],
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false, 
            showData: true,
            slide: 0,
            slides: [
                // { id: 1, text: 'Production Output', value: 'production'},
                // { id: 2, text: 'Resin (PASSERITE HG 3027), Alkali (Na OH), Degreaser', value: 'resin'},
                // { id: 3, text: 'Gas: H2, N2, NG', value: 'gas'},
                // { id: 4, text: 'Metal: AL & ZN', value: 'metal'},
                { id: 5, text: 'Chemical', value: 'chemical'},
            ],
            show: true,
            loading_mach_id: false,
            summaries: []
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getMachType()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        async getMachType(event){
            const respData = await backendApi.fetchCore(`/api/v3/sm/material-consumption/mach-type?mill_id=SR`, null, false, false, false) 
            if (respData.status === 200) {
                
                this.machine_types = respData.data
            }
        },
        async getMachID(event){
            if (event === '' || event === null) {
                this.mach_id = ''
            } else {
                this.loading_mach_id = true
                const respData = await backendApi.fetchCore(`/api/v3/sm/material-consumption/mach-id?mill_id=SR&mach_type=${this.machine_type ? this.machine_type : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.machine_ids = respData.data
                    this.loading_mach_id = false
                }
            }
        },
        async setSlide(index){
            this.slide = index
            await this.search()
        },
        getChart(dt1, container, data1){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: false,
                // title: {
                //     text: title,
                //     fontFamily: "Calibri",
                //     fontSize: 20
                // },
                // subtitles:[
                //     {
                //         text: label,
                //         //Uncomment properties below to see how they behave
                //         fontFamily: "Calibri",
                //         fontColor: "red",
                //         fontSize: 12
                //     }
                // ],
                axisY: {
                    scaleBreaks: {
                        autoCalculate: true,
                        type: "wavy"
                    },
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: this.slides[this.slide].value == 'chemical' ? "Kg" : "TON",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    // cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    fontSize: 11
                },
                data: [ data1 ]
            });

            chart.options.data[0].dataPoints = dt1;
            // chart.options.data[1].dataPoints = dt2;
            this.showDefaultText(chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");
            if (x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },        
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
                e.chart.render();
        },
        showDefaultText(chart, text) {
            var dataPoints = chart.options.data[0].dataPoints;
            var isEmpty = !(dataPoints && dataPoints.length > 0);

            if (!isEmpty) {
                for (var i = 0; i < dataPoints.length; i++) {
                isEmpty = !dataPoints[i].y;
                if (!isEmpty)
                    break;
                }
            }

            if (!chart.options.subtitles)
                chart.options.subtitles = [];
            if (isEmpty) {
                chart.options.subtitles.push({
                text: text,
                verticalAlign: 'center',
                });
                chart.options.data[0].showInLegend = false;
            } else {
                chart.options.data[0].showInLegend = true;
            }
        },
        async delay(ms) {
            // return await for better async stack trace support in case of errors.
            return await new Promise(resolve => setTimeout(resolve, ms));
        },
        async search(){
            this.$store.dispatch('setOverlay', true)

            if (this.date_from == '' || this.date_to == '') {
                this.$store.dispatch('setOverlay', false)

                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please start Period and end period",
                    visible: true
                };
                return false
            }

            var reqBody = {
                'start_period' : this.date_from ? new Date(this.date_from).toISOString().substr(0, 7).replace(/-/g,"") : '',
                'end_period' : this.date_to ? new Date(this.date_to).toISOString().substr(0, 7).replace(/-/g,"") : '',
                'machine_type' : this.machine_type,
                'machine_id' : this.machine_id,
                'category' : this.slides[this.slide].value
            }       
            this.show = true
            const respData = await backendApi.fetchCore('/api/v3/sm/material-consumption/summary', reqBody, false, false, false)
            if (respData.status === 200) {
                this.summaries = respData.data
                await this.delay(2000);
                await this.getSummary(respData.data)
                await this.getData()
                this.show = false
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },
        async getData(){
            var slide = this.slides[this.slide].value
            var reqBody = {
                'start_period' : this.date_from ? new Date(this.date_from).toISOString().substr(0, 7).replace(/-/g,"") : '',
                'end_period' : this.date_to ? new Date(this.date_to).toISOString().substr(0, 7).replace(/-/g,"") : '',
                'machine_type' : this.machine_type,
                'machine_id' : this.machine_id,
                'category' : slide
            }    
            
            var reqBodyProd = {
                'start_period' : this.date_from ? new Date(this.date_from).toISOString().substr(0, 7).replace(/-/g,"") : '',
                'end_period' : this.date_to ? new Date(this.date_to).toISOString().substr(0, 7).replace(/-/g,"") : '',
                'machine_type' : this.machine_type,
                'machine_id' : this.machine_id,
                'category' : 'production'
            }    

            const respData = await backendApi.fetchCore('/api/v3/sm/material-consumption', reqBody, false, false, false)
            const respDataProd = await backendApi.fetchCore('/api/v3/sm/material-consumption', reqBodyProd, false, false, false)
            const respDataMstRatio = await backendApi.fetchCore('/api/v3/sm/material-consumption/mst-ratio', reqBody, false, false, false)

            if (respData.status === 200 && respDataProd.status === 200 && respDataMstRatio.status === 200) {
                var inputJson = respData.data
                const transformedData = {};
                inputJson.forEach(item => {
                    const article = item.article ? item.article.trim().toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "").replace(/-/g, "").replace(/\s/g, '').trim() : 'empty';
                    if (!transformedData[article]) {
                        transformedData[article] = [];
                    }
                    transformedData[article].push({
                        x: new Date(item.periode.substr(0, 4) + '-' + item.periode.substr(4, 7)),
                        y: parseFloat(Number(item.total_wgt)),
                        article: item.article.trim(),
                        periode: item.periode
                    });                    
                });
                var inputJsonProd = respDataProd.data
                const transformedDataProd = {};
                inputJsonProd.forEach(item => {
                    const articleProd = item.article ? item.article.trim().toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "").replace(/-/g, "").replace(/\s/g, '').trim() : 'empty';
                    if (!transformedDataProd[articleProd]) {
                        transformedDataProd[articleProd] = [];
                    }
                    transformedDataProd[articleProd].push({
                        x: new Date(item.periode.substr(0, 4) + '-' + item.periode.substr(4, 7)),
                        y: parseFloat(Number(item.total_wgt)),
                        article: item.article.trim(),
                        periode: item.periode
                    });                    
                });
                var inputJsonMstRatio = respDataMstRatio.data
                const transformedDataMstRatio = {};
                inputJsonMstRatio.forEach(item => {
                    const article = item.article ? item.article.trim().toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "").replace(/-/g, "").replace(/\s/g, '').trim() : 'empty';
                    if (!transformedDataMstRatio[article]) {
                        transformedDataMstRatio[article] = [];
                    }
                    transformedDataMstRatio[article].push({
                        x: new Date(item.periode.substr(0, 4) + '-' + item.periode.substr(4, 7)),
                        y: parseFloat(Number(item.max)),
                        article: item.article.trim(),
                        periode: item.periode
                    });                    
                });

                await this.getChartMaterial('containerRatioMaterialUsed', [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, true)

                await this.getChartMaterial('containerMaterialUsed', [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, true)

                var container = 'containerMaterialUsed'
                var containerRatio = 'containerRatioMaterialUsed'
                var containerProduction = 'containerProduction'

                var hasilproduksi = (transformedDataProd.hasilproduksikg) ? transformedDataProd.hasilproduksikg : []

                var dataHasilProduksi = {
                    type: 'column',
                    name: 'Hasil Produksi Ton',
                    indexLabelFontSize: 14,
                    showInLegend: true,
                }

                await this.getChartMaterial(containerProduction, hasilproduksi, [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [],dataHasilProduksi, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, false)

                switch (slide) {
                    case 'production':                           
                            
                            var hasilproduksi = (transformedData.hasilproduksikg) ? transformedData.hasilproduksikg : []

                            var data = {
                                type: 'column',
                                name: 'Hasil Produksi Ton',
                                // indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#0D47A1",
                                showInLegend: true,
                                // indexLabelPlacement: "outside",  
                                // indexLabelOrientation: "horizontal",
                                // color: "#BBDEFB",
                                // yValueFormatString: "#,###,,,.##",
                            }

                            await this.getChartMaterial(container, hasilproduksi, [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], data, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, false)

                            this.showData = false
                                            
                        break;

                    case 'chemical':
                            this.getChemical(transformedData, transformedDataProd, transformedDataMstRatio)
                        break;

                    // case 'metal':
                    //         var alm = (transformedData.alkg) ? transformedData.alkg : [] 
                    //         var ratioAlm = []
                    //         var zn = (transformedData.znkg) ? transformedData.znkg : []
                    //         var ratioZn = []

                    //         for (let index = 0; index < transformedDataProd.hasilproduksikg.length; index++) {
                    //             const element = transformedDataProd.hasilproduksikg[index];
                    //             let itemAlm = alm.find((item) => item.periode == element.periode ); 
                    //             let itemZn = zn.find((item) => item.periode == element.periode ); 
                    //             if (itemAlm) {
                    //                 var total = (Number(itemAlm.y) / Number(element.y)) * 100
                    //                 ratioAlm.push({
                    //                     x: itemAlm.x,
                    //                     y: parseFloat(total),
                    //                     article: 'Ratio'+'-'+itemAlm.article.trim(),
                    //                     periode: itemAlm.periode
                    //                 })
                    //             }
                    //             if (itemZn) {
                    //                 var totalZn = (Number(itemZn.y) / Number(element.y)) * 100
                    //                 ratioZn.push({
                    //                     x: itemZn.x,
                    //                     y: parseFloat(totalZn),
                    //                     article: 'Ratio'+'-'+itemZn.article.trim(),
                    //                     periode: itemZn.periode
                    //                 })
                    //             }
                    //         }

                    //         var data = {
                    //             type: 'column',
                    //             name: 'AL Kg',
                    //             indexLabelFontSize: 14,
                    //             showInLegend: true,
                    //         }
                    //         var data1 = {
                    //             type: 'column',
                    //             name: 'ZN Kg',
                    //             indexLabelFontSize: 14,
                    //             showInLegend: true,
                    //         }

                    //         var dataProd = {
                    //             type: 'line',
                    //             name: 'Ratio AL Kg',
                    //             indexLabelFontSize: 14,
                    //             showInLegend: true,
                    //             lineDashType: "dash",
                    //             yValueFormatString: "###0.00000"
                    //         }
                    //         var dataProd1 = {
                    //             type: 'line',
                    //             name: 'Ratio ZN Kg',
                    //             indexLabelFontSize: 14,
                    //             showInLegend: true,
                    //             lineDashType: "dash",
                    //             yValueFormatString: "###0.00000"
                    //         }
                    //         await this.getChartMaterial(container, alm, zn, [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], data, data1, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, false)
                    //         await this.getChartMaterial(containerRatio, ratioAlm, ratioZn, [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], dataProd, dataProd1, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, false)

                    //         this.showData = false
                    //     break;

                    // case 'gas':
                    //         this.getGas(transformedData, transformedDataProd)
                    //     break;

                    // case 'resin':
                    //         var container = 'containerMaterialUsed'
                    //         console.log(transformedData);

                    //         var degreaserfce6469s = (transformedData.degreaserfce6469s) ? transformedData.degreaserfce6469s : []
                    //         var ratioDegreaserfce6469s = []
                    //         var freerust1903b1 = (transformedData.freerust1903b1) ? transformedData.freerust1903b1 : []
                    //         var ratioFreerust1903b1 = []
                    //         var freerust1916blbluetinted = (transformedData.freerust1916blbluetinted) ? transformedData.freerust1916blbluetinted : []
                    //         var ratioFreerust1916blbluetinted = []
                    //         var freerust1917yx = (transformedData.freerust1917yx) ? transformedData.freerust1917yx : []
                    //         var ratioFreerust1917yx = []
                    //         var freerust1918bktitanium = (transformedData.freerust1918bktitanium) ? transformedData.freerust1918bktitanium : []
                    //         var ratioFreerust1918bktitanium = []
                    //         var unicoatglc202 = (transformedData.unicoatglc202) ? transformedData.unicoatglc202 : []
                    //         var ratioUnicoatglc202 = []

                    //         for (let index = 0; index < transformedDataProd.hasilproduksikg.length; index++) {
                    //             const element = transformedDataProd.hasilproduksikg[index];
                    //             let itemDegreaserfce6469s = degreaserfce6469s.find((item) => item.periode == element.periode ); 
                    //             if (itemDegreaserfce6469s) {
                    //                 var total = (Number(itemDegreaserfce6469s.y) / Number(element.y)) * 100
                    //                 ratioDegreaserfce6469s.push({
                    //                     x: itemDegreaserfce6469s.x,
                    //                     y: parseFloat(total),
                    //                     article: 'Ratio'+'-'+itemDegreaserfce6469s.article.trim(),
                    //                     periode: itemDegreaserfce6469s.periode
                    //                 })
                    //             }
                    //             let itemFreerust1903b1 = freerust1903b1.find((item) => item.periode == element.periode ); 
                    //             if (itemFreerust1903b1) {
                    //                 var totalFreerust1903b1 = (Number(itemFreerust1903b1.y) / Number(element.y)) * 100
                    //                 ratioFreerust1903b1.push({
                    //                     x: itemFreerust1903b1.x,
                    //                     y: parseFloat(totalFreerust1903b1),
                    //                     article: 'Ratio'+'-'+itemFreerust1903b1.article.trim(),
                    //                     periode: itemFreerust1903b1.periode
                    //                 })
                    //             }
                    //             let itemFreerust1916blbluetinted = freerust1916blbluetinted.find((item) => item.periode == element.periode ); 
                    //             if (itemFreerust1916blbluetinted) {
                    //                 var totalFreerust1916blbluetinted = (Number(itemFreerust1916blbluetinted.y) / Number(element.y)) * 100
                    //                 ratioFreerust1916blbluetinted.push({
                    //                     x: itemFreerust1916blbluetinted.x,
                    //                     y: parseFloat(totalFreerust1916blbluetinted),
                    //                     article: 'Ratio'+'-'+itemFreerust1916blbluetinted.article.trim(),
                    //                     periode: itemFreerust1916blbluetinted.periode
                    //                 })
                    //             }
                    //             let itemFreerust1917yx = freerust1917yx.find((item) => item.periode == element.periode ); 
                    //             if (itemFreerust1917yx) {
                    //                 var totalfreerust1917yx = (Number(itemFreerust1917yx.y) / Number(element.y)) * 100
                    //                 ratioFreerust1917yx.push({
                    //                     x: itemFreerust1917yx.x,
                    //                     y: parseFloat(totalfreerust1917yx),
                    //                     article: 'Ratio'+'-'+itemFreerust1917yx.article.trim(),
                    //                     periode: itemFreerust1917yx.periode
                    //                 })
                    //             }
                    //             let itemFreerust1918bktitanium = freerust1918bktitanium.find((item) => item.periode == element.periode ); 
                    //             if (itemFreerust1918bktitanium) {
                    //                 var totalFreerust1918bktitanium = (Number(itemFreerust1918bktitanium.y) / Number(element.y)) * 100
                    //                 ratioFreerust1918bktitanium.push({
                    //                     x: itemFreerust1918bktitanium.x,
                    //                     y: parseFloat(totalFreerust1918bktitanium),
                    //                     article: 'Ratio'+'-'+itemFreerust1918bktitanium.article.trim(),
                    //                     periode: itemFreerust1918bktitanium.periode
                    //                 })
                    //             }
                    //             let itemUnicoatglc202 = unicoatglc202.find((item) => item.periode == element.periode ); 
                    //             if (itemUnicoatglc202) {
                    //                 var totalUnicoatglc202 = (Number(itemUnicoatglc202.y) / Number(element.y)) * 100
                    //                 ratioUnicoatglc202.push({
                    //                     x: itemUnicoatglc202.x,
                    //                     y: parseFloat(totalUnicoatglc202),
                    //                     article: 'Ratio'+'-'+itemUnicoatglc202.article.trim(),
                    //                     periode: itemUnicoatglc202.periode
                    //                 })
                    //             }
                    //         }

                    //         var data = {
                    //             type: 'column',
                    //             name: 'DEGREASER FC-E 6469S Kg',
                    //             indexLabelFontSize: 14,
                    //             showInLegend: true,
                    //         }

                    //         var data1 = {
                    //             type: 'column',
                    //             name: 'FREERUST 1903B1 Kg',
                    //             indexLabelFontSize: 14,
                    //             showInLegend: true,
                    //         }

                    //         var data2 = {
                    //             type: 'column',
                    //             name: 'FREERUST 1916 BL (BLUE TINTED) Kg',
                    //             indexLabelFontSize: 14,
                    //             showInLegend: true,
                    //         }

                    //         var data3 = {
                    //             type: 'column',
                    //             name: 'FREERUST 1917-YX Kg',
                    //             indexLabelFontSize: 14,
                    //             showInLegend: true,
                    //         }

                    //         var data4 = {
                    //             type: 'column',
                    //             name: 'FREERUST 1918 BK (TITANIUM) Kg',
                    //             indexLabelFontSize: 14,
                    //             showInLegend: true,
                    //         }

                    //         var data5 = {
                    //             type: 'column',
                    //             name: 'UNICOAT GLC 202 Kg',
                    //             indexLabelFontSize: 14,
                    //             showInLegend: true,
                    //         }

                    //         var dataProd = {
                    //             type: 'line',
                    //             name: 'DEGREASER FC-E 6469S Kg',
                    //             indexLabelFontSize: 14,
                    //             showInLegend: true,
                    //             lineDashType: "dash",
                    //             yValueFormatString: "###0.00000"
                    //         }

                    //         var dataProd1 = {
                    //             type: 'line',
                    //             name: 'FREERUST 1903B1 Kg',
                    //             indexLabelFontSize: 14,
                    //             showInLegend: true,
                    //             lineDashType: "dash",
                    //             yValueFormatString: "###0.00000"
                    //         }

                    //         var dataProd2 = {
                    //             type: 'line',
                    //             name: 'FREERUST 1916 BL (BLUE TINTED) Kg',
                    //             indexLabelFontSize: 14,
                    //             showInLegend: true,
                    //             lineDashType: "dash",
                    //             yValueFormatString: "###0.00000"
                    //         }

                    //         var dataProd3 = {
                    //             type: 'line',
                    //             name: 'FREERUST 1917-YX Kg',
                    //             indexLabelFontSize: 14,
                    //             showInLegend: true,
                    //             lineDashType: "dash",
                    //             yValueFormatString: "###0.00000"
                    //         }

                    //         var dataProd4 = {
                    //             type: 'line',
                    //             name: 'FREERUST 1918 BK (TITANIUM) Kg',
                    //             indexLabelFontSize: 14,
                    //             showInLegend: true,
                    //             lineDashType: "dash",
                    //             yValueFormatString: "###0.00000"
                    //         }

                    //         var dataProd5 = {
                    //             type: 'line',
                    //             name: 'UNICOAT GLC 202 Kg',
                    //             indexLabelFontSize: 14,
                    //             showInLegend: true,
                    //             lineDashType: "dash",
                    //             yValueFormatString: "###0.00000"
                    //         }
                            

                    //         await this.getChartMaterial(container, degreaserfce6469s, freerust1903b1, freerust1916blbluetinted,freerust1917yx, freerust1918bktitanium, unicoatglc202, [], [], [], [], [], [], [], [], [], [], [], [], [], [], data, data1, data2, data3, data4, data5, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, false)

                    //         await this.getChartMaterial(containerRatio, ratioDegreaserfce6469s, ratioFreerust1903b1, ratioFreerust1916blbluetinted, ratioFreerust1917yx, ratioFreerust1918bktitanium, ratioUnicoatglc202, [], [], [], [], [], [], [], [], [], [], [], [], [], [], dataProd, dataProd1, dataProd2, dataProd3, dataProd4, dataProd5, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, false)


                    //         this.showData = false
                    //     break;

                    default:
                        break;
                }
            }

            
        },
        async getSummary(datas){
            var data = {
                type: "bar",
                name: "Total Wgt",
                axisYType: "secondary",
                indexLabel: "{y}",
                indexLabelFontSize: 14,
                indexLabelFontColor: "#0D47A1",
                showInLegend: false,
                indexLabelPlacement: "outside",  
                indexLabelOrientation: "horizontal",
                color: "#82B1FF",
                // yValueFormatString: "#,###,,,.##",
            }

            for (let index = 0; index < datas.length; index++) {
                const element = datas[index];

                var dt = []

                for (let i = 0; i < element.data.length; i++) {
                    const elm = element.data[i];
                    dt.push({y: parseFloat(Number(elm.total_wgt)), label: elm.periode})
                }

                this.getChart(dt, 'container' + index, data)
                
            }
        },
        // async getGas(transformedData, transformedDataProd){
        //     var container = 'containerMaterialUsed'
        //     var containerRatio = 'containerRatioMaterialUsed'

        //     var h2m3 = (transformedData.h2m3) ? transformedData.h2m3 : []
        //     var ratioh2m3 = []
        //     var n2m3 = (transformedData.n2m3) ? transformedData.n2m3 : []
        //     var ration2m3 = []
        //     var ngm3 = (transformedData.ngm3) ? transformedData.ngm3 : []
        //     var rationgm3 = []

        //     for (let index = 0; index < transformedDataProd.hasilproduksikg.length; index++) {
        //         const element = transformedDataProd.hasilproduksikg[index];
        //         let itemH2m3 = h2m3.find((item) => item.periode == element.periode ); 
        //         if (itemH2m3) {
        //             var total = (Number(itemH2m3.y) / Number(element.y)) * 100
        //             ratioh2m3.push({
        //                 x: itemH2m3.x,
        //                 y: parseFloat(total),
        //                 article: 'Ratio'+'-'+itemH2m3.article.trim(),
        //                 periode: itemH2m3.periode
        //             })
        //         }
        //         let itemN2m3 = n2m3.find((item) => item.periode == element.periode ); 
        //         if (itemN2m3) {
        //             var totaln2m3 = (Number(itemN2m3.y) / Number(element.y)) * 100
        //             ration2m3.push({
        //                 x: itemN2m3.x,
        //                 y: parseFloat(totaln2m3),
        //                 article: 'Ratio'+'-'+itemN2m3.article.trim(),
        //                 periode: itemN2m3.periode
        //             })
        //         }
        //         let itemNgm3 = ngm3.find((item) => item.periode == element.periode ); 
        //         if (itemNgm3) {
        //             var totalngm3 = (Number(itemNgm3.y) / Number(element.y)) * 100
        //             rationgm3.push({
        //                 x: itemNgm3.x,
        //                 y: parseFloat(totalngm3),
        //                 article: 'Ratio'+'-'+itemNgm3.article.trim(),
        //                 periode: itemNgm3.periode
        //             })
        //         }
        //     }

        //     var data = {
        //         type: 'column',
        //         name: 'H2 M3',
        //         indexLabelFontSize: 14,
        //         showInLegend: true,
        //     }
        //     var data1 = {
        //         type: 'column',
        //         name: 'N2 m3',
        //         indexLabelFontSize: 14,
        //         showInLegend: true,
        //     }       
        //     var data3 = {
        //         type: 'column',
        //         name: 'NG m3',
        //         indexLabelFontSize: 14,
        //         showInLegend: true,
        //     }

        //     var dataProd = {
        //         type: 'line',
        //         name: 'H2 M3',
        //         indexLabelFontSize: 14,
        //         showInLegend: true,
        //         lineDashType: "dash",
        //         yValueFormatString: "###0.00000"
        //     }
        //     var dataProd1 = {
        //         type: 'line',
        //         name: 'N2 m3',
        //         indexLabelFontSize: 14,
        //         showInLegend: true,
        //         lineDashType: "dash",
        //         yValueFormatString: "###0.00000"
        //     }       
        //     var dataProd3 = {
        //         type: 'line',
        //         name: 'NG m3',
        //         indexLabelFontSize: 14,
        //         showInLegend: true,
        //         lineDashType: "dash",
        //         yValueFormatString: "###0.00000"
        //     }
            
        //     await this.getChartMaterial(container, h2m3, n2m3, [], ngm3, [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], data, data1, {}, data3, {},  {},  {},  {},  {},  {},  {},  {},  {},  {},  {},  {},  {},  {}, {}, {}, false)
        //     await this.getChartMaterial(containerRatio, ratioh2m3, ration2m3, [], rationgm3, [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], dataProd, dataProd1, {}, dataProd3, {},  {},  {},  {},  {},  {},  {},  {},  {},  {},  {},  {},  {},  {}, {}, {}, false)

        //     this.showData = false
        // },
        async getChemical(transformedData, transformedDataProd, transformedDataMstRatio){
            var container = 'containerMaterialUsed'
            var containerRatio = 'containerRatioMaterialUsed'
            console.log(transformedDataMstRatio);
            var bisulfitltr = (transformedData.consumablehcl) ? transformedData.consumablehcl : []
            var ckemical7330 = (transformedData.consumablerollingoil) ? transformedData.consumablerollingoil : []
            var ckemical7408 = (transformedData.chemical7408ro) ? transformedData.chemical7408ro : []
            var ckemicalpc77cip = (transformedData.chemicalpc77cip) ? transformedData.chemicalpc77cip : []
            var ckemicalpc98cip = (transformedData.chemicalpc98cip) ? transformedData.chemicalpc98cip : []
            var eba1000kg = (transformedData.eba1000kg) ? transformedData.eba1000kg : []
            var garamsoftener1 = (transformedData.garamsoftener1kg) ? transformedData.garamsoftener1kg : []
            var garamsoftener2 = (transformedData.garamsoftener2kg) ? transformedData.garamsoftener2kg : []
            var h2so4ltr = (transformedData.h2so4ltr) ? transformedData.h2so4ltr : []
            var hclltr = (transformedData.hclltr) ? transformedData.hclltr : []
            var maxtrol3743ltr = (transformedData.maxtrol3743ltr) ? transformedData.maxtrol3743ltr : []
            var maxtrol3782ltr = (transformedData.maxtrol3782ltr) ? transformedData.maxtrol3782ltr : []
            var polymeranionltr = (transformedData.polymeranionltr) ? transformedData.polymeranionltr : []
            var polymerkationltr = (transformedData.polymerkationltr) ? transformedData.polymerkationltr : []

            var ratioBisulfitltr = []
            var ratioCkemical7330 = []
            var ratioCkemical7408 = []
            var ratioCkemicalpc77cip = []
            var ratioCkemicalpc98cip = []
            var ratioEba1000kg = []
            var ratioGaramsoftener1 = []
            var ratioGaramsoftener2 = []
            var ratioH2so4ltr = []
            var ratioHclltr = []
            var ratioMaxtrol3743ltr = []
            var ratioMaxtrol3782ltr = []
            var ratioPolymeranionltr = []
            var ratioPolymerkationltr = []

            for (let index = 0; index < transformedDataProd.hasilproduksikg.length; index++) {
                const element = transformedDataProd.hasilproduksikg[index];
                let itembisulfitltr = bisulfitltr.find((item) => item.periode == element.periode ); 
                if (itembisulfitltr) {
                    console.log(itembisulfitltr.article.trim())
                    var total = ((Number(itembisulfitltr.y) * 1000) / Number(element.y)) 
                    ratioBisulfitltr.push({
                        x: itembisulfitltr.x,
                        y: parseFloat(total),
                        article: 'Ratio'+'-'+itembisulfitltr.article.trim(),
                        periode: itembisulfitltr.periode
                    })
                    // Tambah max consumable hcl
                }
                let itemckemical7330 = ckemical7330.find((item) => item.periode == element.periode ); 
                if (itemckemical7330) {
                    var total = (Number(itemckemical7330.y) / Number(element.y)) 
                    ratioCkemical7330.push({
                        x: itemckemical7330.x,
                        y: parseFloat(total),
                        article: 'Ratio'+'-'+itemckemical7330.article.trim(),
                        periode: itemckemical7330.periode
                    })
                    // Tambah max rolling acid
                }
                let itemckemical7408 = ckemical7408.find((item) => item.periode == element.periode ); 
                if (itemckemical7408) {
                    var total = (Number(itemckemical7408.y) / Number(element.y)) * 100
                    ratioCkemical7408.push({
                        x: itemckemical7408.x,
                        y: parseFloat(total),
                        article: 'Ratio'+'-'+itemckemical7408.article.trim(),
                        periode: itemckemical7408.periode
                    })
                }
                let itemckemicalpc77cip = ckemicalpc77cip.find((item) => item.periode == element.periode ); 
                if (itemckemicalpc77cip) {
                    var total = (Number(itemckemicalpc77cip.y) / Number(element.y)) * 100
                    ratioCkemicalpc77cip.push({
                        x: itemckemicalpc77cip.x,
                        y: parseFloat(total),
                        article: 'Ratio'+'-'+itemckemicalpc77cip.article.trim(),
                        periode: itemckemicalpc77cip.periode
                    })
                }
                let itemckemicalpc98cip = ckemicalpc98cip.find((item) => item.periode == element.periode ); 
                if (itemckemicalpc98cip) {
                    var total = (Number(itemckemicalpc98cip.y) / Number(element.y)) * 100
                    ratioCkemicalpc98cip.push({
                        x: itemckemicalpc98cip.x,
                        y: parseFloat(total),
                        article: 'Ratio'+'-'+itemckemicalpc98cip.article.trim(),
                        periode: itemckemicalpc98cip.periode
                    })
                }
                let itemeba1000kg = eba1000kg.find((item) => item.periode == element.periode ); 
                if (itemeba1000kg) {
                    var total = (Number(itemeba1000kg.y) / Number(element.y)) * 100
                    ratioEba1000kg.push({
                        x: itemeba1000kg.x,
                        y: parseFloat(total),
                        article: 'Ratio'+'-'+itemeba1000kg.article.trim(),
                        periode: itemeba1000kg.periode
                    })
                }
                let itemgaramsoftener1 = garamsoftener1.find((item) => item.periode == element.periode ); 
                if (itemgaramsoftener1) {
                    var total = (Number(itemgaramsoftener1.y) / Number(element.y)) * 100
                    ratioGaramsoftener1.push({
                        x: itemgaramsoftener1.x,
                        y: parseFloat(total),
                        article: 'Ratio'+'-'+itemgaramsoftener1.article.trim(),
                        periode: itemgaramsoftener1.periode
                    })
                }
                let itemgaramsoftener2 = garamsoftener2.find((item) => item.periode == element.periode ); 
                if (itemgaramsoftener2) {
                    var total = (Number(itemgaramsoftener2.y) / Number(element.y)) * 100
                    ratioGaramsoftener2.push({
                        x: itemgaramsoftener2.x,
                        y: parseFloat(total),
                        article: 'Ratio'+'-'+itemgaramsoftener2.article.trim(),
                        periode: itemgaramsoftener2.periode
                    })
                }
                let itemh2so4ltr = h2so4ltr.find((item) => item.periode == element.periode ); 
                if (itemh2so4ltr) {
                    var total = (Number(itemh2so4ltr.y) / Number(element.y)) * 100
                    ratioH2so4ltr.push({
                        x: itemh2so4ltr.x,
                        y: parseFloat(total),
                        article: 'Ratio'+'-'+itemh2so4ltr.article.trim(),
                        periode: itemh2so4ltr.periode
                    })
                }
                let itemhclltr = hclltr.find((item) => item.periode == element.periode ); 
                if (itemhclltr) {
                    var total = (Number(itemhclltr.y) / Number(element.y)) * 100
                    ratioHclltr.push({
                        x: itemhclltr.x,
                        y: parseFloat(total),
                        article: 'Ratio'+'-'+itemhclltr.article.trim(),
                        periode: itemhclltr.periode
                    })
                }
                let itemmaxtrol3743ltr = maxtrol3743ltr.find((item) => item.periode == element.periode ); 
                if (itemmaxtrol3743ltr) {
                    var total = (Number(itemmaxtrol3743ltr.y) / Number(element.y)) * 100
                    ratioMaxtrol3743ltr.push({
                        x: itemmaxtrol3743ltr.x,
                        y: parseFloat(total),
                        article: 'Ratio'+'-'+itemmaxtrol3743ltr.article.trim(),
                        periode: itemmaxtrol3743ltr.periode
                    })
                }
                let itemmaxtrol3782ltr = maxtrol3782ltr.find((item) => item.periode == element.periode ); 
                if (itemmaxtrol3782ltr) {
                    var total = (Number(itemmaxtrol3782ltr.y) / Number(element.y)) * 100
                    ratioMaxtrol3782ltr.push({
                        x: itemmaxtrol3782ltr.x,
                        y: parseFloat(total),
                        article: 'Ratio'+'-'+itemmaxtrol3782ltr.article.trim(),
                        periode: itemmaxtrol3782ltr.periode
                    })
                }
                let itempolymeranionltr = polymeranionltr.find((item) => item.periode == element.periode ); 
                if (itempolymeranionltr) {
                    var total = (Number(itempolymeranionltr.y) / Number(element.y)) * 100
                    ratioPolymeranionltr.push({
                        x: itempolymeranionltr.x,
                        y: parseFloat(total),
                        article: 'Ratio'+'-'+itempolymeranionltr.article.trim(),
                        periode: itempolymeranionltr.periode
                    })
                }
                let itempolymerkationltr = polymerkationltr.find((item) => item.periode == element.periode ); 
                if (itempolymerkationltr) {
                    var total = (Number(itempolymerkationltr.y) / Number(element.y)) * 100
                    ratioPolymerkationltr.push({
                        x: itempolymerkationltr.x,
                        y: parseFloat(total),
                        article: 'Ratio'+'-'+itempolymerkationltr.article.trim(),
                        periode: itempolymerkationltr.periode
                    })
                }
            }

            var data = {
                type: 'column',
                name: 'Consumable HCL ltr',
                indexLabelFontSize: 14,
                showInLegend: true, 
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }
            var data1 = {
                type: 'column',
                name: 'Consumable Rolling Oil kg',
                indexLabelFontSize: 14,
                showInLegend: true,
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }
            var data2 = {
                type: 'column',
                name: 'Chemical 7408 RO',
                indexLabelFontSize: 14,
                showInLegend: true,
            }            
            var data3 = {
                type: 'column',
                name: 'Chemical PC 77 cip',
                indexLabelFontSize: 14,
                showInLegend: true,
            }
            var data4 = {
                type: 'column',
                name: 'Chemical PC 98 cip',
                indexLabelFontSize: 14,
                showInLegend: true,
            }
            var data5 = {
                type: 'column',
                name: 'EBA 1000 kg',
                indexLabelFontSize: 14,
                showInLegend: true,
            }
            var data6 = {
                type: 'column',
                name: 'Garam softener 1 Kg',
                indexLabelFontSize: 14,
                showInLegend: true,
            }
            var data7 = {
                type: 'column',
                name: 'Garam softener 2 Kg',
                indexLabelFontSize: 14,
                showInLegend: true,
            }
            var data9 = {
                type: 'column',
                name: 'H2SO4 ltr',
                indexLabelFontSize: 14,
                showInLegend: true,
            }
            var data10 = {
                type: 'column',
                name: 'HCL ltr',
                indexLabelFontSize: 14,
                showInLegend: true,
            }
            var data11 = {
                type: 'column',
                name: 'Maxtrol 3743 ltr',
                indexLabelFontSize: 14,
                showInLegend: true,
            }
            var data12 = {
                type: 'column',
                name: 'Maxtrol 3782 ltr',
                indexLabelFontSize: 14,
                showInLegend: true,
            }
            var data16 = {
                type: 'column',
                name: 'Polymer Anion ltr',
                indexLabelFontSize: 14,
                showInLegend: true,
            }
            var data17 = {
                type: 'column',
                name: 'Polymer Kation ltr',
                indexLabelFontSize: 14,
                showInLegend: true,
            }

            var dataProd = {
                type: 'line',
                name: 'Consumable HCL ml/ton',
                indexLabelFontSize: 14,
                showInLegend: true,
                lineDashType: "dash",
                yValueFormatString: "###0.00",
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }
            var dataProd1 = {
                type: 'line',
                name: 'Consumable Rolling Oil kg/ton',
                indexLabelFontSize: 14,
                showInLegend: true,
                lineDashType: "dash",
                yValueFormatString: "###0.00",
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }
            var dataProd2 = {
                type: 'line',
                name: 'Chemical 7408 RO',
                indexLabelFontSize: 14,
                showInLegend: true,
                lineDashType: "dash",
                yValueFormatString: "###0.00000",
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }            
            var dataProd3 = {
                type: 'line',
                name: 'Chemical PC 77 cip',
                indexLabelFontSize: 14,
                showInLegend: true,
                lineDashType: "dash",
                yValueFormatString: "###0.00000",
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }
            var dataProd4 = {
                type: 'line',
                name: 'Chemical PC 98 cip',
                indexLabelFontSize: 14,
                showInLegend: true,
                lineDashType: "dash",
                yValueFormatString: "###0.00000",
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }
            var dataProd5 = {
                type: 'line',
                name: 'EBA 1000 kg',
                indexLabelFontSize: 14,
                showInLegend: true,
                lineDashType: "dash",
                yValueFormatString: "###0.00000",
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }
            var dataProd6 = {
                type: 'line',
                name: 'Garam softener 1 Kg',
                indexLabelFontSize: 14,
                showInLegend: true,
                lineDashType: "dash",
                yValueFormatString: "###0.00000",
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }
            var dataProd7 = {
                type: 'line',
                name: 'Garam softener 2 Kg',
                indexLabelFontSize: 14,
                showInLegend: true,
                lineDashType: "dash",
                yValueFormatString: "###0.00000",
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }
            var dataProd9 = {
                type: 'line',
                name: 'H2SO4 ltr',
                indexLabelFontSize: 14,
                showInLegend: true,
                lineDashType: "dash",
                yValueFormatString: "###0.00000",
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }
            var dataProd10 = {
                type: 'line',
                name: 'HCL ltr',
                indexLabelFontSize: 14,
                showInLegend: true,
                lineDashType: "dash",
                yValueFormatString: "###0.00000",
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }
            var dataProd11 = {
                type: 'line',
                name: 'Maxtrol 3743 ltr',
                indexLabelFontSize: 14,
                showInLegend: true,
                lineDashType: "dash",
                yValueFormatString: "###0.00000",
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }
            var dataProd12 = {
                type: 'line',
                name: 'Maxtrol 3782 ltr',
                indexLabelFontSize: 14,
                showInLegend: true,
                lineDashType: "dash",
                yValueFormatString: "###0.00000",
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }
            var dataProd16 = {
                type: 'line',
                name: 'Polymer Anion ltr',
                indexLabelFontSize: 14,
                showInLegend: true,
                lineDashType: "dash",
                yValueFormatString: "###0.00000",
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }
            var dataProd17 = {
                type: 'line',
                name: 'Polymer Kation ltr',
                indexLabelFontSize: 14,
                showInLegend: true,
                lineDashType: "dash",
                yValueFormatString: "###0.00000",
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }

            var dataMstRatioHcl = {
                type: 'line',
                name: 'Max Consumable HCL ml/ton',
                indexLabelFontSize: 14,
                showInLegend: true,
                yValueFormatString: "###0.00",
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }
            var dataMstRatioRoll = {
                type: 'line',
                name: 'Max Consumable Rolling Oil kg/ton',
                indexLabelFontSize: 14,
                showInLegend: true,
                yValueFormatString: "###0.00",
                indexLabel: "{y}",
                indexLabelFontColor: "#0D47A1",
            }

            var mstRatioHcl = (transformedDataMstRatio.consumablehcl) ? transformedDataMstRatio.consumablehcl : []
            var mstRatioRoll = (transformedDataMstRatio.consumablerollingoil) ? transformedDataMstRatio.consumablerollingoil : []

            await this.getChartMaterial(container, bisulfitltr, ckemical7330, ckemical7408, ckemicalpc77cip, ckemicalpc98cip, eba1000kg, garamsoftener1, garamsoftener2, [], h2so4ltr, hclltr, maxtrol3743ltr, maxtrol3782ltr, [], [], [], polymeranionltr, polymerkationltr, [], [], [], [], data, data1, data2, data3, data4, data5, data6, data7, {}, data9, data10, data11, data12, {}, {}, {}, data16, data17, {}, {}, {}, {}, false)

            await this.getChartMaterial(containerRatio, ratioBisulfitltr, ratioCkemical7330, ratioCkemical7408, ratioCkemicalpc77cip, ratioCkemicalpc98cip, ratioEba1000kg, ratioGaramsoftener1, ratioGaramsoftener2, [], ratioH2so4ltr, ratioHclltr, ratioMaxtrol3743ltr, ratioMaxtrol3782ltr, [], [], [], ratioPolymeranionltr, ratioPolymerkationltr, [], [], mstRatioHcl, mstRatioRoll, dataProd, dataProd1, dataProd2, dataProd3, dataProd4, dataProd5, dataProd6, dataProd7, {}, dataProd9, dataProd10, dataProd11, dataProd12, {}, {}, {}, dataProd16, dataProd17, {}, {}, dataMstRatioHcl, dataMstRatioRoll, false)

            this.showData = false
        },
        getChartMaterial(container, dt1, dt2, dt3, dt4, dt5, dt6, dt7, dt8, dt9, dt10, dt11, dt12, dt13, dt14, dt15, dt16, dt17, dt18, dt19, dt20, dt21, dt22, data1, data2, data3, data4, data5, data6, data7, data8, data9, data10, data11, data12, data13, data14, data15, data16, data17, data18, data19, data20, data21, data22, destroy = false){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: true,
                axisX: {
                    interval: 2,
                    intervalType: "month"
                },
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    // title: this.slides[this.slide].value == 'chemical' ? "Kg" : "TON",
                    labelFormatter: this.addSymbols,                    
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    // cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    fontSize: 11
                },
                data: [
                    data1, data2, data3, data4, data5, data6, data7, data8, data9, data10, data11, data12, data13, data14, data15, data16, data17, data18, data19, data20, data21, data22
                ]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;
            chart.options.data[2].dataPoints = dt3;
            chart.options.data[3].dataPoints = dt4;
            chart.options.data[4].dataPoints = dt5;
            chart.options.data[5].dataPoints = dt6;
            chart.options.data[6].dataPoints = dt7;
            chart.options.data[7].dataPoints = dt8;
            chart.options.data[8].dataPoints = dt9;
            chart.options.data[9].dataPoints = dt10;
            chart.options.data[10].dataPoints = dt11;
            chart.options.data[11].dataPoints = dt12;
            chart.options.data[12].dataPoints = dt13;
            chart.options.data[13].dataPoints = dt14;
            chart.options.data[14].dataPoints = dt15;
            chart.options.data[15].dataPoints = dt16;
            chart.options.data[16].dataPoints = dt17;
            chart.options.data[17].dataPoints = dt18;
            chart.options.data[18].dataPoints = dt19;
            chart.options.data[19].dataPoints = dt20;
            chart.options.data[20].dataPoints = dt21;
            chart.options.data[21].dataPoints = dt22;


            // this.showDefaultText(chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");

            if (destroy == true) {
                chart.destroy();
            } else {
                if (x.matches) {

                    for(var i = 0; i < chart.options.data.length; i++){
                        chart.options.data[i].indexLabelFontSize = 6;
                    }
                    chart.render();
                }

                chart.render();
            }
        },
    },
    watch: {
    }
}
</script>
    